input[type="text"], input[type="password"]
{
  width: 32em;
}

.forgot.link 
{
  margin-top: 1em;
  display: inline-block !important;
}

.submit.button
{
  display: inline-block !important;
}